
.ui.buttons.control-buttons {
  position: absolute;
  right: -2px;
  top: -1px;
  z-index: 100;
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ui.buttons.control-buttons .button {
  opacity: .7;
}
.ui.buttons.control-buttons .button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ui.buttons.control-buttons .button:last-child {
  border-bottom-right-radius: 0;
}
.ui.buttons.control-buttons .ui.basic.button:focus,
.ui.buttons.control-buttons .ui.basic.button:active,
.ui.buttons.control-buttons .ui.basic.button:hover {
  /* Там в библиотеке тоже important :( */
  background-color: #eee !important;
  opacity: 1;
}
.generator-item-header {
  line-height: 27px;
  margin: 0 10px;
}
.ui.button.add {
  width: 100%;
  margin-bottom: 1rem;
}
.markdown-editor .CodeMirror {
  height: 150px;
  min-height: 100px;
}
