
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main.container {
  margin: 7em auto;
  flex: 1;
}
.logo {
  padding: 10px 10px 5px;
}
.ui.menu.fixed.left {
  z-index: 103;
}
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eeeeeeee;
  z-index: 102;
}
.bar-container {
  cursor: pointer;
}
.bar {
  width: 20px;
  height: 3px;
  background-color: #ffffffe6;
  margin: 4px 0;
  transition: 0.4s;
}
