
@font-face {
  font-family: 'kanji-stroke-orders';
  src: url(/assets/fonts/KanjiStrokeOrders.ttf);
}
.ui.form textarea.yomi-text:not([rows]) {
  font-family: 'kanji-stroke-orders'; /* NOQA */
  height: 4em;
  margin: 20px 0;
  padding: 15px;
}
